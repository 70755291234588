import memoize from 'lodash.memoize';
import { debounce } from 'throttle-debounce';

import {
  CART_PRODUCT_KEY_HAS_CHANGED,
  CART_UPDATED_EVENT,
  OPTIN_PRODUCT,
  OPTOUT_PRODUCT,
  PRODUCT_CHANGE_FREQUENCY,
  PRODUCT_CHANGE_PREPAID_SHIPMENTS,
  RECEIVE_OFFER,
  REQUEST_OFFER,
  SETUP_CART,
  SETUP_PRODUCT
} from '../core/constants';

import { makeSubscribedSelector } from '../core/selectors';
import { getOrCreateHidden, safeProductId } from '../core/utils';
import { getTrackingKey } from './shopifyTrackingMiddleware';

const SHOPIFY_ROOT = window.Shopify?.routes?.root || '/';
const CART_PAGE_URL = '/cart';
const CART_JS_URL = `${SHOPIFY_ROOT}cart.js`;
const PRODUCTS_URL = `${SHOPIFY_ROOT}products/`;

/**
 * List of section DOM elements to update via section-rendering api https://shopify.dev/api/section-rendering
 */
const DEFAULT_SHOPIFY_CART_AJAX_SECTIONS =
  '[id^="shopify-section-"][id$=__cart-items], [id^="shopify-section-"][id$="__cart-footer"],#cart-live-region-text,#cart-icon-bubble';
const makeSyncProductId = offer =>
  debounce(100, false, function(form) {
    const { id } = Object.fromEntries([...new FormData(form).entries()]);
    if (id) {
      offer.setAttribute('product', id);
    } else {
      offer.removeAttribute('product');
    }
  });

async function setupPdp(store, offer) {
  const handle = guessProductHandle(offer);
  if (handle) {
    try {
      const product = await getProduct(handle);
      store.dispatch({ type: SETUP_PRODUCT, payload: { product, offer } });
    } catch (err) {
      console.warn('OG: Unable to fetch product details for PDP', err);
    }
  }
  // try closest form (safer)
  let form = offer.closest('form');
  // sometimes template is so closest does not work
  // <div>
  //   <og-offer ..>
  // </div>
  // <div>
  //   <form action="/cart/add"/>
  // </div>
  if (!form) {
    let ref = offer.parentElement;
    // look up parents element of offer that contains the form. This will fix eventually category offers.
    while (ref) {
      form = ref.querySelector('form[action$="/cart/add"]');
      if (form) break;
      if (ref.tagName.toLowerCase() === 'body') break;
      ref = ref.parentElement;
    }
  }

  if (form) {
    // since syncProductId is debounced not matter which comes first mutation or onchange
    const syncProductId = makeSyncProductId(offer);
    form.addEventListener('change', ev => syncProductId(form));
    const mo = new MutationObserver(() => syncProductId(form));
    mo.observe(form, { subtree: true, childList: true });
  } else {
    console.info('no /cart/add form found for og-offer', offer);
  }
}

const getCart = async () => await (await fetch(CART_JS_URL)).json();

/**
 * Attemps to guess the product handle o
 * @returns
 */
export function guessProductHandle(offer): String {
  return (
    [
      // Allow specify data-shopify-product-handle attribute offer level so it will work on category qv
      // <og-offer product="{{ card_product.selected_or_first_available_variant.id }}"
      //   data-shopify-product-handle="{{ card_product.handle }}"
      //   location="category"></og-offer>
      () => offer?.dataset.shopifyProductHandle,
      () =>
        // Use the oembed to get the product handle
        (document
          .querySelector('[href$=".oembed"]')
          ?.getAttribute('href')
          ?.match(/\/([^\/]+)\.oembed$/) || [])[1],

      () =>
        // Use the open graph og:type==product and og:url to get the product handle
        ((document.querySelector('meta[property="og:type"][content="product"]') &&
          document
            .querySelector('meta[property="og:url"][content]')
            ?.getAttribute('content')
            ?.match(/\/([^\/]+)$/)) ||
          [])[1],

      () =>
        // use any json in the markup
        [...document.querySelectorAll('[type$=json]')]
          .map(it => JSON.parse(it.textContent || '{}'))
          .find(it => it.handle && it.price)?.handle
    ]
      // returns the first truthy and prevent call next functions
      .reduce((acc, cur) => acc || cur(), '')
  );
}

const getProduct = memoize(async handle => (await fetch(`${PRODUCTS_URL}${handle}.js`)).json());

async function setupCart(store, offer) {
  const cart = await getCart();
  const { items } = cart;
  store.dispatch({ type: SETUP_CART, payload: cart });

  // some minicart templates does not contains line.key but contains line which corresponds to
  // the index on the cart items (Vedge)

  const productAsCartLine = Number(offer.product.id);
  if (productAsCartLine <= items.length) {
    offer.setAttribute('product', items[productAsCartLine - 1].key);
  }

  const products = await Promise.all(Array.from(new Set(items.map(({ handle }) => handle))).map(getProduct));
  products.forEach(product => store.dispatch({ type: SETUP_PRODUCT, payload: { product, offer } }));
}

/**
 * Synchronizes the optins/optouts using shopify cart ajax api
 *
 * @param action
 * @param store
 */
export async function synchronizeCartOptin(action: any, store: any) {
  const offerElement = action.payload.offer;
  const selling_plan = action.payload.frequency || getSubscribedFrequency(action.payload.product.id, store);
  const trackingEvent = getTrackingEvent(action);

  if (!offerElement?.isCart) {
    return;
  }

  try {
    // disable the interactions on the offer since we need to process its side-effects first.
    offerElement.style.pointerEvents = 'none';
    offerElement.style.opacity = '.7';

    const sectionsToUpdate = Array.from(document.querySelectorAll(DEFAULT_SHOPIFY_CART_AJAX_SECTIONS));

    const key = action.payload.product.id; // shopify cart.item.key
    const cart = await getCart();
    const offerIx = cart?.items?.findIndex(it => it.key === key); // cart.items[offerIx];
    const item = cart.items[offerIx];
    const qty = item.quantity;
    const productId = safeProductId(key);

    const res = await fetch('/cart/change.js', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: key,
        quantity: qty,
        attributes: Object.fromEntries([trackingEvent]),
        properties: item.properties,
        selling_plan: selling_plan || null,
        sections: sectionsToUpdate.map((el: HTMLElement) => el.id.replace(/^shopify-section-/, ''))
      })
    });

    if (res.status !== 200) throw new Error('Cart not updated');

    const newCart = await res.json();

    // If both carts have same length we can update the item.key
    // to the original offer element, at least provide
    // some graceful degradations if no sections nor cart page
    const newKey =
      cart.items.length === newCart.items.length
        ? newCart.items[offerIx].key
        : newCart.items.find(
            line =>
              line.quantity === qty &&
              line.product_id === productId &&
              ((!selling_plan && !line.selling_plan_allocation) ||
                line?.selling_plan_allocation.selling_plan.id === selling_plan)
          )?.key;

    if (newKey) {
      store.dispatch({
        type: CART_PRODUCT_KEY_HAS_CHANGED,
        payload: {
          oldCartProductKey: key,
          newCartProductKey: newKey
        }
      });

      offerElement.setAttribute('product', newKey);
    }

    // dispatch SETUP_CART so offer does not flip the state
    store.dispatch({ type: SETUP_CART, payload: newCart });

    // Use a custom event to hook custom cart updates.
    const cartUpdateEvent = new CustomEvent(CART_UPDATED_EVENT, { bubbles: true, cancelable: true });
    offerElement.dispatchEvent(cartUpdateEvent);

    // Let client uses preventDefault if they want to skip default logic after event.
    if (cartUpdateEvent.defaultPrevented) return;

    const sections = newCart.sections;

    if (Object.values(sections).length) {
      sectionsToUpdate.forEach((sectionElement: HTMLElement) => {
        const sectionId = sectionElement.id.replace(/^shopify-section-/, '');
        if (!(sectionId in sections)) return;

        const sectionRawHtml = sections[sectionId];

        const el = new DOMParser()
          .parseFromString(sectionRawHtml.toString() || '', 'text/html')
          .getElementById(sectionElement.id);
        if (el) {
          sectionElement.innerHTML = el.innerHTML;
        }
      });
    } else if (window.location.pathname.startsWith(CART_PAGE_URL)) {
      // only do if we are on the cart page
      window.location.reload();
    }
  } catch (err) {
    console.log('OG Error updating cart', err);
  } finally {
    offerElement.style.pointerEvents = 'auto';
    offerElement.style.opacity = '1';
  }
}

/**
 * Returns a tracking event adhering to the below format:
 *
 * og__<ts in seconds>: "<product_id>,<action>,<location>,<selling_plan optional>,<variation_id optional>"
 *
 * Examples:
 *
 *   - optin_product with selling plan ID 123 and variation ID 456:
 *     og__165653130: "optin_product,pdp,123,456"
 *
 *   - optin_product with no selling plan and variation ID 456:
 *     og__165653137: "optin_product,pdp,,456"
 *
 *   - optin_product with selling plan ID 123 and no variation ID:
 *     og__165653139: "optin_product,pdp,123,"
 *
 *   - optin_product with no selling plan and no variation ID:
 *     og__165653141: "optin_product,pdp,,"
 *
 *   - optout_product with variation id 456:
 *     og__165653135: "optout_product,pdp,,456"
 *
 *   - product_change_frequency with selling plan ID 123 and variation ID 456:
 *     og__165653131: "product_change_frequency,pdp,123,456"
 *
 * @param  action a Redux action
 * @return {Array} an array with positional values key, value
 */
export function getTrackingEvent(action): Array<string> {
  const product_id = action.payload.product.id;
  if (!product_id) return [];
  const key = getTrackingKey();
  const location = action.payload.offer?.location || '';
  const variation = action.payload.offer?.variationId || '';
  const value = [product_id, action.type.toLowerCase(), location];

  switch (action.type) {
    case REQUEST_OFFER:
    case OPTOUT_PRODUCT:
      value.push(''); // No selling plan should be associated with these actions
      value.push(variation);
      break;
    case OPTIN_PRODUCT:
    case PRODUCT_CHANGE_FREQUENCY:
      value.push(action.payload.frequency);
      value.push(variation);
      break;
    default:
      return []; // we dont track anything else
  }

  return [key, value.join(',')];
}

export function getSubscribedFrequency(productId, store) {
  const subscribedSelector = makeSubscribedSelector({ id: productId });
  const sellingPlanId = subscribedSelector(store.getState())?.frequency;
  return sellingPlanId;
}

/**
 * // update <input type="hidden" name="selling_plan"/> if available
 *
 * @param store
 */
function synchronizeSellingPlan(store: any, offerElement?: HTMLElement) {
  if (offerElement.isCart) return; // hidden inputs are used when product page, not cart.

  [...document.querySelectorAll('form[action$="/cart/add"] [name=id]')].forEach((productIdInput: HTMLInputElement) => {
    const productId = productIdInput.value;

    const sellingPlanId = getSubscribedFrequency(productId, store);

    getOrCreateHidden(productIdInput.form, 'selling_plan', sellingPlanId);
    if (offerElement) {
      // use this to update the product attributes in future
    }
  });
}

export default function shopifyMiddleware(store) {
  return next => action => {
    /**
     * This redux middleware will perform Shopify specific side-effects such as change
     * the product selling plan when offer is cart
     */
    switch (action.type) {
      case OPTIN_PRODUCT:
      case OPTOUT_PRODUCT:
      case PRODUCT_CHANGE_FREQUENCY:
        break;
      case REQUEST_OFFER:
        if (action.payload.offer?.isCart) {
          setupCart(store, action.payload.offer);
        } else {
          setupPdp(store, action.payload.offer);
        }
        break;
      default:
    }

    next(action);

    switch (action.type) {
      case OPTIN_PRODUCT:
      case OPTOUT_PRODUCT:
      case PRODUCT_CHANGE_FREQUENCY:
      case PRODUCT_CHANGE_PREPAID_SHIPMENTS:
        synchronizeCartOptin(action, store);
      // falls through
      case REQUEST_OFFER:
      case RECEIVE_OFFER:
      case SETUP_PRODUCT:
        synchronizeSellingPlan(store, action.payload.offer);
        break;
      default:
    }
  };
}
