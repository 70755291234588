import { LitElement, html, css } from 'lit-element';
import { connect } from '../core/connect';
import {
  makeProductPrepaidShipmentOptionsSelector,
  makeProductPrepaidShipmentsOptedInSelector,
  makePrepaidShipmentsSelectedSelector
} from '../core/selectors';
import { productChangePrepaidShipments } from '../core/actions';
import { withProduct } from '../core/resolveProperties';

export class PrepaidToggle extends withProduct(LitElement) {
  constructor() {
    super();
    this.options = [];
    this.text = 'shipments';
  }

  static get properties() {
    return {
      options: { type: Array },
      text: { type: String },
      shipmentsOptedIn: { type: Number },
      prepaidShipmentsSelected: { type: Number },
      defaultPrepaidShipments: { type: Number, attribute: 'default-prepaid-shipments' }
    };
  }

  // copied from SelectFrequency
  static get styles() {
    return css`
      og-select {
        display: inline-block;
        cursor: pointer;
        background-color: var(--og-select-bg-color, #fff);
        border: var(--og-select-border, 1px solid #aaa);
        border-width: var(--og-select-border-width, 1px);
        box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
        z-index: 1;
      }

      input {
        width: 1.2em;
        height: 1.2em;
        accent-color: var(--og-prepaid-checkbox-color, black);
        border-radius: 4px;
      }
    `;
  }

  get checked() {
    return this.shipmentsOptedIn > 1;
  }

  get selectedShipments() {
    return this.prepaidShipmentsSelected || this.shipmentsOptedIn || this.getDefaultPrepaidShipments();
  }

  getDefaultPrepaidShipments() {
    return this.options.includes(this.defaultPrepaidShipments)
      ? this.defaultPrepaidShipments
      : this.options[1] || this.options[0];
  }

  handleChange(e) {
    if (e.target.checked) {
      this.productChangePrepaidShipments(this.product, this.selectedShipments, this.offer);
    } else {
      this.productChangePrepaidShipments(this.product, null, this.offer);
    }
  }

  handleSelect({ target: { value } }) {
    const valueAsNumber = +value;
    this.productChangePrepaidShipments(this.product, valueAsNumber, this.offer);
  }

  render() {
    if (this.options.length === 0) {
      return html``;
    }

    const displayOptions = this.options.map(value => ({
      value: value,
      text: `${value} ${this.text}`
    }));

    return html`
      <div>
        <input id="cbx" type="checkbox" .checked=${this.checked} @change=${this.handleChange} />
        <label for="cbx">
          <slot name="label">Prepay for</slot>
          ${this.options.length > 1
            ? html`
                <og-select
                  .options=${displayOptions}
                  .selected=${this.selectedShipments}
                  .onChange="${e => this.handleSelect(e)}"
                ></og-select>
              `
            : html`
                <span>${displayOptions[0].text}</span>
              `}
          <slot name="append"></slot>
        </label>
      </div>
    `;
  }
}

const mapStateToProps = (state, ownProps) => ({
  options: makeProductPrepaidShipmentOptionsSelector(ownProps.product.id)(state),
  shipmentsOptedIn: makeProductPrepaidShipmentsOptedInSelector(ownProps.product)(state),
  prepaidShipmentsSelected: makePrepaidShipmentsSelectedSelector(ownProps.product)(state)
});

const ConnectedPrepaidToggle = connect(mapStateToProps, { productChangePrepaidShipments })(PrepaidToggle);

export { ConnectedPrepaidToggle };
